.badge-container {
    min-height: 35vh;
    background-color: #f8fafc;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (min-width: 768px) {
    .badge-container {
      padding: 2rem;
    }
  }
  
  .badge-card {
    max-width: 36rem;
    margin: 0 auto;
  }
  
  .badge-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .badge-header {
      flex-direction: row;
    }
  }
  
  .badge-logo {
    width: 10rem;
    height: 10rem;
  }
  
  .badge-title-container {
    flex: 1;
    text-align: center;
  }
  
  @media (min-width: 768px) {
    .badge-title-container {
      text-align: left;
    }
  }
  
  .badge-subtitle {
    font-size: 1.125rem;
    color: #475569;
  }
  
  .verified-by {
    color: #2563eb;
  }
  
  .badge-tag-container {
    margin-top: 1rem;
  }
  
  .status-tag {
    padding: 0.25rem 1rem;
    font-size: 1rem;
  }
  
  .verification-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .verification-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .verification-card {
    background-color: #f8fafc;
  }
  
  .verification-label {
    font-size: 0.875rem;
    color: #64748b;
  }
  
  .verification-value {
    font-size: 1.125rem;
    font-weight: 600;
  }
  
  .info-section {
    margin-bottom: 1.5rem;
  }
  
  .info-text {
    color: #475569;
    display: block;
    margin-bottom: 1rem;
  }
  
  .incident-section {
    text-align: center;
  }